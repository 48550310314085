.Browser.light {
    border: 3px solid #e8e1e1;
}
.Browser.light > .header {
    background-color: #e8e1e1;
}
.Browser.light > .header .tabs li {
    background-color: #f7f2f2;
    color: #666;
}
.Browser.light > .header .tabs li:last-child {
    background-color: #e8e1e1;
    color: #999;
}
.Browser.light .options {
    background-color: #f7f2f2;
}
.Browser.light .options .navigation li:hover {
    background-color: #e8e1e1;
}
.Browser.light .options .url {
    background-color: #e8e1e1;
    color: #333;
}
.Browser.light .options .url .protocolo {
    color: #333;
}
.Browser.light .options .theme {
    color: #666;
}
.Browser.light .emptyArea {
    background-color: #fff;
}

.Browser.dark {
    border: 3px solid #202124;
}
.Browser.dark > .header {
    background-color: #202124;
}
.Browser.dark > .header .tabs li {
    background-color: #333638;
    color: #fff;
}
.Browser.dark > .header .tabs li:last-child {
    background-color: #202124;
    color: #fff;
}
.Browser.dark .options {
    background-color: #333638;
    border-bottom: 1px solid #434749;
}
.Browser.dark .options .navigation li:hover {
    background-color: #202124;
}
.Browser.dark .options .url {
    background-color: #202124;
    color: #999;
}
.Browser.dark .options .url .protocolo {
    color: #999;
}
.Browser.dark .options .theme {
    color: #999;
    background-color: #202124;
}
.Browser.dark .options .terminal {
    color: #999;
    background-color: #202124;
}
.Browser.dark .options .other {
    color: #999;
    background-color: #202124;
}

.Browser.dark .emptyArea {
    background-color: #333638;
}
.Browser.dark .BrowserConsole .header {
    background-color: #202124;
}
.Browser.dark .BrowserConsole .logArea {
    background-color: #333638;
}
.Browser.dark .BrowserConsole .logArea .grayLog {
    color: #fff;
}
.Browser.dark .BrowserConsole {
    border-left: 0;
}

/*BrowserConsole*/
.BrowserConsole {
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid #c7c6c6;
}
.BrowserConsole .header {
    height: 30px;
    background-color: #f6f6f6;
    padding: 5px 0;
}
.BrowserConsole .header .closeBtnConsole {
    width: 40px;
    height: calc(100% - 5px);
    display: inline-block;
    border-right: 1px solid #c7c6c6;
    color: #999;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    text-align: center;
    padding-top: 5px;
}
.BrowserConsole .header .closeBtnConsole:hover {
    color: #666;
}
.BrowserConsole .header .consoleTitle {
    display: inline-block;
    height: 30px;
    text-align: center;
    font-size: 16px;
    color: #666;
    padding: 0 15px;
}
.BrowserConsole .logArea {
    height: calc(100% - 35px);
    background-color: #fff;
    overflow-y: scroll;
}
.BrowserConsole .logArea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background-color: #e4e4e4;
}
.BrowserConsole .logArea::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
}
.BrowserConsole .logArea::-webkit-scrollbar-thumb {
    border-radius: 6px;
    width: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #272a33;
}
.BrowserConsole .logArea .grayLog {
    color: #666;
    display: block;
    padding: 3px 10px;
    font-size: 13px;
}
.BrowserConsole .logArea .grayLog svg {
    font-size: 11px;
    color: #999;
    margin-right: 5px;
    color: #72d0d3;
}
.BrowserConsole .logArea .throwError {
    background-color: #e0becc;
    color: #ce4863;
    border-top: 1px solid #ce4863;
    border-bottom: 1px solid #ce4863;
    display: block;
    margin: 10px 0;
    padding: 5px;
    font-size: 13px;
}
.BrowserConsole .logArea .throwError svg {
    font-size: 12px;
    margin-right: 5px;
}

.Browser {
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
}
.Browser .header .tabs {
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
}
.Browser .header .tabs .favicon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: 2px;
}
.Browser .header .tabs li {
    padding: 6px 15px;
    font-size: 14px;
    list-style: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: inline-flex;
}

.Browser .header .tabs li:last-child {
    padding: 6px 10px;
    border: 0;
}

.Browser .options {
    padding: 5px 10px;
}
.Browser .options .navigation {
    padding: 0;
    margin: 0;
    display: inline-block;
    float: left;
    width: 90px;
}

.Browser .options .navigation li {
    display: inline-block;
    float: left;
    color: #999;
}
.Browser .options .navigation li {
    font-size: 17px;
    transition: all 0.1s ease-in-out;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.Browser .options .navigation li:last-child {
    font-size: 15px;
    font-weight: bold;
    margin-top: 1px;
}
.Browser .options .url {
    display: inline-block;
    float: left;
    margin-left: 15px;
    width: calc(100% - 45vh);
    padding: 7px 20px;
    border-radius: 20px;
    font-size: 14px;
}
.Browser .options .terminal {
    width: 30px;
    color: #666;
    font-size: 14px;
    float: right;
    text-align: center;
    padding-top: 4px;
    display: inline-block;
    height: 26px;
    cursor: pointer;
    margin: 1px 16px 0 5px;
    background-color: #e8e1e1;
    border-radius: 50%;
}
.Browser .options .other {
    width: max-content;
    color: #666;
    font-size: 14px;
    float: right;
    text-align: center;
    padding-top: 4px;
    display: inline-block;
    height: 33px;
    cursor: pointer;
    margin: 1px 16px 0 5px;
    background-color: #e8e1e1;
    border-radius: 1rem;
    padding: 0.5rem;
}
.Browser .options .url .protocolo {
    font-size: 12px;
    margin-right: 15px;
}
.Browser .options .theme {
    width: 30px;
    color: #666;
    font-size: 16px;
    padding-top: 4px;
    margin-top: 1px;
    height: 26px;
    cursor: pointer;
    background-color: #e8e1e1;
    border-radius: 50%;
    float: right;
    text-align: center;
    padding-top: 4px;
}

.Browser .emptyArea {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Browser .renderArea {
    position: relative;
    height: 100%;
}

.Browser .renderArea .frame {
    border: 0;
}

.both {
    clear: both;
}
