#verticalButtons{
    display:flex;
    flex-direction:column;
}

button{
    border: none;
    border-radius: 5px;
    padding: 5px 5px 7px 5px;
    margin: 2px;
    cursor:pointer;
}
button:hover{
    background:blue;
}

#networkCanvas{
    background:black;
    position: absolute;
    top: 0;
    left: 0;
}
#carCanvas{
    background:lightgray;
}