@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    -webkit-tap-highlight-color: transparent;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  html {
    @apply h-full;
    scroll-behavior: smooth;
  }

  body {
    @apply h-full bg-secondary-50;
  }
}

@layer components {
}

.shape1 {
  position: absolute;
  top: 0;
  z-index: 1;
  right: 0;
  background: rgba(55, 48, 163);
  height: 150px;
  width: 200px;
  border-bottom-left-radius: 100%;
}

.shape2 {
  position: absolute;
  z-index: 1;
  height: 150px;
  width: 200px;
  bottom: 0;
  left: 0;
  background: #ffc662;
  border-top-right-radius: 100%;
}

button:focus {
  outline: none;
}
