@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");

* {
  font-family: "Source Sans Pro", sans-serif;
}

/*NodeConsole*/
.NodeConsole {
  background-color: #202124;
  width: 100%;
  height: 305px;
  border-radius: 5px;
}

.NodeConsole .whiteLog {
  color: #fff;
  padding: 4px 6px 0 0;
  display: inline-block;
}
.NodeConsole .grayLog {
  color: #999;
  padding: 4px 6px 0 0;
  display: inline-block;
}
.NodeConsole .greenLog {
  color: #4f9906;
  padding: 4px 6px 0 0;
  display: inline-block;
  font-weight: bold;
}
.NodeConsole .yellowLog {
  color: #b99527;
  padding: 4px 6px 0 0;
  display: inline-block;
}
.NodeConsole .throwError {
  background-color: #290504;
  color: #ee7c7d;
  border: 1px solid #ee7c7d;
  display: block;
  margin: 10px 0;
  padding: 8px;
}
.NodeConsole .logArea {
  padding: 15px 25px;
  font-size: 13px;
  height: 235px;
  overflow-y: scroll;
}

.NodeConsole .logArea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #1e1e1e;
}

.NodeConsole .logArea::-webkit-scrollbar {
  width: 10px;
  background-color: #1e1e1e;
}

.NodeConsole .logArea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #272a33;
}

.NodeConsole .header {
  background-color: #333638;
  height: 38px;
  border-radius: 5px;
}
.NodeConsole .header ul {
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
}
.NodeConsole .header ul li {
  list-style: none;
  padding: 10px 25px;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  background-color: #202124;
  display: inline-block;
  border-top-left-radius: 5px;
}
